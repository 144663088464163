import * as React from "react"

const dstyle = {
    backgroundColor: "#1b1e23",
    width: "100%",
}

const pstyle = {
    display: "inline-block",
    padding: "5px 0px 5px 0px",
    borderLeft: "5px solid red",
    margin: "2px",
    color: "#eae6e5",
}

const astyle = {
    color: "#eae6e5",
    display: "inline",
    // todo - hover color
}

function GrabcadLink(prop) {
    return (<div style={dstyle}><p style={pstyle}>This project is on GrabCad: <a style={astyle} href={prop}>Link</a></p></div>)
}

export default GrabcadLink