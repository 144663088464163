import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import GrabcadLink from "../components/GrabcadLink"
import GithubLink from "../components/GithubLink"

import AllImg from "../images/marblesorter/all.jpg"
import BarcodeImg from "../images/marblesorter/barcode.jpg"
import RetreivalImg from "../images/marblesorter/retreival.jpg"
import RevolverImg from "../images/marblesorter/revolver.jpg"
import TopImg from "../images/marblesorter/top.jpg"
import ImageGallery from "../components/ImageGallery"

const imgs = [
    {
        img: AllImg,
        alt: "Completed marble sorter",
    },
    {
        img: TopImg,
        alt: "Marble Serializer subassembly (top)",
    },
    {
        img: RetreivalImg,
        alt: "Marble retrieval subassembly",
    },
    {
        img: BarcodeImg,
        alt: "Barcode reader subassembly",
    },
    {
        img: RevolverImg,
        alt: "Revolver (Storage) subassembly",
    },
]

const MarbleSorterPage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>ENGR 112 - Marble Sorter</h1>
            {GithubLink("https://github.com/Gautreaux/ENGR112-Marble-Sorter")}
            {GrabcadLink("https://grabcad.com/library/tamu-engr-112-marble-sorter-1")}
            <p>ENGR 112 was an infamous class at Texas A&amp;M: it was the class which consisted primarily of designing a device which color-sorted and dispensed marbles. The class/project had several nicknames including "marbles loser" and "freshman capstone" among other less nice things. Provided to the students where a collection of marbles, a LEGO Mindstorms (original), and a box of popsicle sticks. It was expected that the students, in teams of 8, would construct a marble sorter/dispenser over the course of the spring semester. I, of course, threw my SolidWorks experience at the problem, resulting in the best looking, but not most functional, marble sorter.</p>
            <p>The basic problem could be broken into a few categories. The marbles had to be serialized, stored, then dispensed according to a barcode read in another part of the system. Going through systematically, serialization occurred first at the top of the machine. The marbles were placed in a bowl. A spinning flower turned in the bowl and took only one marble at a time. The marbles were then passed over a color sensor to attempt to discern the color. This is where the most trouble occurred. Several of the provided marbles were practically indistinguishable from one another by the LEGO NXT color sensor (White glass, Semi-translucent white, reflective silver were all options). Once a guess was made at the color, the revolver would position itself to receive the marble. The revolver had one tube per marble and each tube was only wide enough for one marble, forcing them to stack vertically. Between the color sensor and the revolver, a mechanism of two carefully spaced rails separated the marbles by size. A light sensor was used to determine which direction the marble rolled and thus its size. Assuming the marbles were sorted correctly, dispensing was much simpler. Beneath the revolver, a second rotating disk, in conjunction with careful positioning of the revolver, dispensed one marble at a time. The barcode reader was affixed to the right of the system. It used a wheel to feed the barcode and a color sensor to read the black and white stripes.</p>
            <p>The control system was not that interesting. The project required either LabVIEW or MATLAB; we chose the latter. The whole process basically runs in two loops: sort and (read and dispense). The sensors used are just color and encoders. Everything is basically, done with dead reckoning. The motors are controlled with simple P loops (PID was tried at one point, but at the time we could not get it working reliably) and zeroed where possible by stalling.</p>
            <p>Overall, the project was successful. We got to present our project, along with other teams at the Engineering Showcase 2018. We did not win the judging, but our design was certainly the cleanest, sturdiest, and smallest option there.</p>
            <p>Looking back four (wow!) years later, I don't have much to add. It was a simple project given my FRC/professional experience and we leveraged it well. I wish that we had access to better sensors, but at the time we made do with what we had. I'm still friends with a couple of the people from this team, particularly the software people. Its still one of the most complex physical things I've built for a class (which would be sad if I wasn't a computer science major).</p>
            <p>TODO: photos</p>
            <ImageGallery value={imgs}/>
            {FooterBar()}
        </div>
    )
}

export default MarbleSorterPage